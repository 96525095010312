import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby-link'
import { graphql, Link } from 'gatsby'
import Button from '../components/Button'
import Layout from '../components/Layout'
import imageupload from '../img/image-upload.svg'
import usStates from '../data/USStates'

const states = Object.values(usStates)
const grades = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
]

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

const StudentFields = ({ state, onChange, handleStudent }) => {
  return (
    <div>
      <label className="block my-5" htmlFor="isStudent">
        <input
          className="mr-2 leading-tight"
          type="checkbox"
          id="isStudent"
          name="isStudent"
          onChange={handleStudent}
        />
        <span className="text-sm">I am a student</span>
      </label>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
            htmlFor="schoolName"
          >
            Name of school
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="schoolName"
            name="schoolName"
            type="text"
            placeholder="PS 261"
            onChange={onChange}
          />
        </div>
        <div className="w-full md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
            htmlFor="grade"
          >
            Grade
          </label>
          <div className="relative h-select">
            <select
              className="block h-full appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grade"
              name="grade"
              onChange={onChange}
              defaultValue=""
            >
              <option hidden value="">
                Select
              </option>

              {grades.map(grade => (
                <option key={grade} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SubmitForm = ({ data }) => {
  const allTags = data.allWpTag.edges

  const [state, setState] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [tags, setTags] = useState([])
  const [usedTags, setUsedTags] = useState([])
  const [uploadedImage, setUploadedImage] = useState()
  const [fullState, setFullState] = useState({})
  const [serverResponse, setServerResponse] = useState(``)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleTerms = e => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  const handleNewsletter = e => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  const handleStudent = e => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  const handleTags = e => {
    if (!tags.includes(e.target.value)) {
      setTags([...tags, e.target.value])
    }
  }

  const removeTag = e => {
    setTags(tags.filter(item => item !== e.target.dataset.id))
  }

  const handleAttachment = e => {
    if (e.target.files.length && e.target.files[0].size > 2200000) {
      alert('File is too big!')
      //  e.target.files[0] = "";
    } else if (e.target.files.length) {
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
        path: e.target.files[0].path,
      })
      setUploadedImage(URL.createObjectURL(event.target.files[0]))
    }
  }

  useEffect(() => {
    setFullState({ ...state, tags })

    const filteredTags = allTags.filter(function(tag) {
      return tags.includes(tag.node.databaseId.toString())
    })

    setUsedTags(filteredTags)
    return function cleanup() {}
  }, [state, tags])

  async function handleSubmit(e) {
    e.preventDefault()
    setSubmitting(true)
    const form = e.target

    const response = await window
      .fetch(`/api/submit-your-story`, {
        method: 'POST',
        body: encode({
          'form-name': form.getAttribute('name'),
          ...fullState,
        }),
      })
      .then(setSubmitting(false))
      .then(navigate(form.getAttribute('action')))
      
    setServerResponse(response)
  }

  return (
    <Layout>
      <div className="max-w-screen-xl mx-auto md:mt-10 p-5">
        <div className="mx-auto mt-10 p-5 max-w-3xl">
          
        </div>
        <form
          name="submit-story"
          method="post"
          action="/thanks/"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="submit-story" />

          <p hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>

          <fieldset className="fieldset">
            <legend className="legend">Your Information</legend>
            <p className="mb-5 text-gray-500">
              We are collecting this info in case we need to get in touch with
              you about your submission. If you are submitting this form for a
              student, please put your name and email address here.
            </p>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="Jane"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Doe"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="you@email.com"
                  onChange={handleChange}
                  required
                />
                <label className="block" htmlFor="newsletter">
                  <input
                    className="mr-2 leading-tight"
                    type="checkbox"
                    id="newsletter"
                    name="newsletter"
                    onChange={handleNewsletter}
                  />
                  <span className="text-sm">
                    Subscribe to MOFAD's newsletter (optional)
                  </span>
                </label>
              </div>
            </div>

            <StudentFields
              state={state}
              onChange={handleChange}
              handleStudent={handleStudent}
            />
          </fieldset>

          <fieldset className="fieldset">
            <legend className="legend mb-5">Your Culinary Hero</legend>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="heroName"
                >
                  Name of African American Culinary Hero
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="heroName"
                  name="heroName"
                  type="text"
                  placeholder=""
                  required
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="gender"
                >
                  Pronouns
                </label>
                <div className="relative h-select">
                  <select
                    className="block h-full appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="gender"
                    name="gender"
                    onChange={handleChange}
                    required
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option>She/Her</option>
                    <option>He/Him</option>
                    <option>They/Them</option>
                    <option>N/A</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="century"
                >
                  Time Period
                </label>
                <div className="relative h-select">
                  <select
                    className="block h-full appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="century"
                    name="century"
                    onChange={handleChange}
                    required
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option>1619-1718</option>
                    <option>1719-1818</option>
                    <option>1819-1918</option>
                    <option>1919-Present</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="city"
                >
                  City (optional)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="city"
                  name="city"
                  type="text"
                  placeholder="Albuquerque"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="state"
                >
                  State (optional)
                </label>
                <div className="relative h-select">
                  <select
                    className="block h-full appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="state"
                    name="state"
                    onChange={handleChange}
                    defaultValue=""
                  >
                    <option hidden value="">
                      Select
                    </option>

                    {states.map(us => (
                      <option key={us} value={us}>
                        {us}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <p className="block uppercase tracking-wide text-gray-700 text-sm font-bold mt-5">
                  Category <span className="text-gray-500">(Select One)</span>
                </p>
                <p className="block text-gray-700 text-sm font-bold mb-2">
                  What has your African American culinary hero contributed to
                  food and drink?
                </p>

                <div className="flex flex-wrap">
                  {data.allWpCategory.edges.map(cat => (
                    <label
                      key={cat.node.databaseId}
                      htmlFor={cat.node.name}
                      className="p-3"
                    >
                      <input
                        className="mx-1"
                        id={cat.node.name}
                        value={cat.node.databaseId}
                        name="category"
                        type="radio"
                        onChange={handleChange}
                        required
                      />
                      {cat.node.name}
                    </label>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="tags"
                >
                  Tags (optional)
                </label>
                <div className="relative h-select">
                  <select
                    className="block appearance-none w-full h-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="tags"
                    name="tags"
                    onChange={handleTags}
                    defaultValue=""
                  >
                    <option value="" hidden>
                      Select all that apply
                    </option>

                    {allTags.map(tag => (
                      <option
                        key={tag.node.databaseId}
                        value={tag.node.databaseId}
                      >
                        {tag.node.name}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div className="py-4">
                  {usedTags &&
                    usedTags.map(tag => (
                      <span
                        key={tag.node.databaseId}
                        data-id={tag.node.databaseId}
                        onClick={removeTag}
                        className="py-2 px-3 mr-2 mb-2 border border-gray-500 inline-block cursor-pointer hover:border-gray-900 rounded-full text-sm"
                      >
                        <span className="text-gray-500 text-gray-900 pointer-events-none">
                          X
                        </span>
                        <span className="pl-2 tracking-wide uppercase pointer-events-none">
                          {tag.node.name}
                        </span>
                      </span>
                    ))}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="description"
                >
                  Tell us about them{' '}
                  <span className="text-gray-500">
                    (280 character limit: If your submission is approved, this
                    text will be displayed in the exhibition)
                  </span>
                </label>

                <textarea
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="description"
                  name="description"
                  maxLength="280"
                  placeholder=""
                  required
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="descriptionLong"
                >
                  If you'd like, tell us more{' '}
                  <span className="text-gray-500">
                    (1500 character limit: If your submission is approved, this
                    text will be displayed online)
                  </span>
                </label>
                <textarea
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="descriptionLong"
                  name="descriptionLong"
                  maxLength="1500"
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="attachment"
                >
                  Image <span className="text-gray-500">(Max Size: 2MB)</span>
                </label>
                <p className="mb-5 text-gray-500">
                  Upload a photo or drawing of your African American culinary
                  hero or something that best represents them (such as a place,
                  an ingredient, or a piece of kitchen equipment).
                </p>
                <div className="overflow-hidden relative block appearance-none w-full h-50vh bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 flex flex-col items-center justify-center">
                  {uploadedImage ? (
                    <img
                      src={uploadedImage}
                      alt="upload"
                      style={{ width: 'auto', maxWidth: '50%' }}
                      loading="lazy"
                    />
                  ) : (
                    <div>
                      <img
                        src={imageupload}
                        alt="upload"
                        style={{ width: '88px' }}
                        loading="lazy"
                      />
                      <span>Upload Image</span>
                    </div>
                  )}

                  <input
                    className="opacity-0 absolute min-h-full min-w-full inset-0"
                    id="attachment"
                    type="file"
                    accept="image/*"
                    name="attachment"
                    onChange={handleAttachment}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                  htmlFor="imageDescription"
                >
                  Image Description (max 40 characters)
                </label>
                <textarea
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="imageDescription"
                  name="imageDescription"
                  maxLength="40"
                  placeholder=""
                  onChange={handleChange}
                />
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend className="legend">Terms and Conditions</legend>
            <div className="mx-auto max-w-3xl p-5">
              <label className="block my-5" htmlFor="terms">
                <input
                  className="mr-2 leading-tight"
                  type="checkbox"
                  id="terms"
                  name="terms"
                  onChange={handleTerms}
                />
                <span className="text-sm">
                  I accept the terms and conditions.
                </span>
              </label>
              <p>
                By clicking submit, you give MOFAD permission to publish and
                share your story online, in the exhibition, and on social media.
                MOFAD reserves the right to not publish your story or to remove
                it from the website and the exhibition at any time without
                notice. Approved stories will not be visible immediately online
                or in the exhibition. Submissions may take up to three weeks to
                be approved. Accept{' '}
                <Link
                  className="underline"
                  to={`/terms`}
                  state={{ modal: true, noScroll: true }}
                >
                  Terms of Use
                </Link>
                .
              </p>
            </div>
          </fieldset>

          <div className="text-center my-10">
            <Button
              disabled={!state.terms || submitting ? true : false}
              type="submit"
            >
              {submitting ? 'Submitting' : 'Submit Story'}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CatsAndTags {
    allWpCategory(filter: { databaseId: { ne: 1 } }) {
      edges {
        node {
          databaseId
          name
        }
      }
    }
    allWpTag(filter: { databaseId: { ne: 2 } }) {
      edges {
        node {
          databaseId
          name
        }
      }
    }
  }
`

export default SubmitForm
