import React from 'react'

const Button = ({ children, small, className, type, alt, disabled }) => {
  return disabled ? (
    <button
      type={type || 'button'}
      disabled
      className={`border-solid border-2 tracking-widest transition-colors duration-150
        ${className} 
        ${
          small
            ? `py-2 px-4 text-base md:text-base`
            : `py-3 px-6 text-base`
        }
        ${
          alt
            ? `border-white bg-neutral text-white hover:bg-neutral hover:text-white`
            : `border-white bg-neutral text-white hover:bg-neutral hover:text-white`
        }`
      }
    >
      {children}
    </button>
  ) : (
    <button
      type={type || 'button'}
      className={`border-solid border-2 tracking-widest transition-colors duration-150
      ${className}
      ${
        small
          ? `py-2 px-4 text-base md:text-base`
          : `py-3 px-6 text-base`
      } 
      ${
        alt
          ? `border-white bg-white text-red hover:border-darkwhite hover:bg-darkwhite hover:text-darkred`
          : `border-red bg-red text-white hover:border-darkred hover:bg-darkred hover:text-darkwhite`
      }`}
    >
      {children}
    </button>
  )
}

export default Button
