import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import logo from '../img/mofad-logo-text.png'
import Button from './Button'

const Navbar = () => (
  <nav>
    <div id="nav" className="flex justify-between p-3 items-center bg-blue">
      <div className="flex items-center gap-3">
        <a target="_blank" href="https://www.mofad.org" className="flex-shrink-0 w-24">
          <img src={logo} alt="" loading="eager" />
          <span className='sr-only'>Visit mofad.org homepage</span>
        </a>
        <Link to="/" activeStyle={{ display: "none" }} className="flex-shrink-0 text-base md:text-md text-white hover:text-red transition-colors duration-150">
          back <span className="hidden md:inline" >to quilt</span>
        </Link>
      </div>
      <div className="flex justify-end items-center gap-3">
        <a target="_blank" href="https://www.mofad.org/about-aa" className="flex items-center text-white hover:text-red transition-colors duration-150">
          <span className="block tracking-wide text-base md:text-md text-right">about the exhibition</span>
        </a>
        <Link
          to="/submit"
        >
          <Button small >submit your story</Button>
        </Link>
      </div>
    </div>
  </nav>
)

export default Navbar
