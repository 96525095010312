import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const Footer = () => {

  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: {eq: 1464}) {
        title
        content
      }
    }
  `)
    
  return (
    <footer className="bg-red pb-20 text-white">
      <h2 className='sr-only'>Footer</h2>
      <div className="max-w-screen-xl mx-auto text-center grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
        <div>
          <h3 className='sr-only'>Subcribe</h3>
          <div className="flex flex-col justify-center items-center" dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
        </div>
        <div className='flex flex-col items-center justify-center gap-1 md:order-first'>
          <h3 className='mb-4'>contact</h3>
          <p>po box 380857<br/>brooklyn, ny 11238</p>
          <a target="_blank" href="https://www.mofad.org/contact" className="hover:text-blue transition-colors duration-150">
            email
          </a>
          <a target="_blank" href="tel:+12013713550" className="hover:text-blue transition-colors duration-150">
            201-371-3550
          </a>
        </div>
        <div className='flex flex-col items-center justify-center gap-1'>
          <h3 className='mb-4'>follow</h3>
          <a target="_blank" href="https://www.facebook.com/MOFADinfo/" className="hover:text-blue transition-colors duration-150">
            facebook
          </a>
          <a target="_blank" href="https://www.instagram.com/mofad" className="hover:text-blue transition-colors duration-150">
            instagram
          </a>
          <a target="_blank" href="https://twitter.com/mofad" className="hover:text-blue transition-colors duration-150">
            twitter
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UCUb6z-yPpWP7oWDV4road1Q" className="hover:text-blue transition-colors duration-150">
            youtube
          </a>
        </div>
        <div className='col-span-full flex items-center justify-center gap-8 md:gap-12'>
          <a target="_blank" href="https://www.mofad.org/about-mofad" className="hover:text-blue transition-colors duration-150">
            about
          </a>
          <a target="_blank" href="https://www.mofad.org/visit-us" className="hover:text-blue transition-colors duration-150">
            visit
          </a>
          <a target="_blank" href="https://mofad.donation.veevartapp.com/donation/view/home/general-fund" className="flex hover:text-blue transition-colors duration-150">
            donate
          </a>
          <a target="_blank" href="https://www.mofad.org/jobs" className="hover:text-blue transition-colors duration-150">
            jobs
          </a>
          <a target="_blank" href="https://www.mofad.org/press" className="hover:text-blue transition-colors duration-150">
            press
          </a>
          <a target="_blank" href="https://www.mofad.org/shop" className="hover:text-blue transition-colors duration-150">
            shop
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
